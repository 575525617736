import { createStore } from 'vuex';

const files = require.context('./', true, /index\.ts$/);

interface StoreModules {
  [key: string]: string | boolean;
}

const modules = files.keys().reduce((aggregatedModules, fileName) => {
  const currentModule = files(fileName).default;
  if (!currentModule) {
    return aggregatedModules;
  }

  const storeModules: StoreModules = {
    namespaced: true,
    ...aggregatedModules,
  };

  if (fileName) {
    let moduleName = fileName.match(/^.\/([A-Za-z]+)\//)?.[1];
    const pathArray = fileName.split('/');

    // Support for nested directory store (i.e. iam/users)
    if (pathArray.length > 3) {
      moduleName = `${moduleName}/${pathArray[2]}`;
    }
    if (moduleName) {
      storeModules[moduleName] = currentModule;
    }
  }
  return storeModules;
}, {});

export default createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
});
