
import FeedbackMessages from 'ewx-aphrodite/components/FeedbackMessages.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    FeedbackMessages,
  },
  mounted: () => document.querySelector('.app-loader')?.remove(),
});
