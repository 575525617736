import { createRouter, createWebHistory } from 'vue-router';

import { firebaseInstance } from 'ewx-zeus/utils/auth';
import storage from 'ewx-zeus/utils/storage';

import store from '@/store';
import config from './config';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Checks if either the route or any parent route requires authorization
  const isAuthRequired = to.matched.some((route) => route.meta?.isAuthRequired);
  // Check if the user has authenticated against Firebase
  const isSignedIn = !!firebaseInstance.currentUser;

  if (isAuthRequired && !isSignedIn) {
    if (to.path !== '/') {
      // A message is shown to show that the requested view is authorized, excluding on the homepage
      store.dispatch('feedback/showPersistentError', 'Sign in required!');

      // As the user is not authenticated, the path is stored it is redirected after signing in
      storage.session.set(config.redirectPathKey, to.fullPath);
    }

    // In case the route requires auth and the user is not yet signed in, redirect to the sign in
    next('/signin');
  } else if (to.matched.some((route) => route.name === 'Sign in') && isSignedIn) {
    // Path to redirect to stored when accessing the application without being signed in
    const redirectPath = storage.session.get(config.redirectPathKey);

    storage.session.remove(config.redirectPathKey);

    // In case the user is signed in and navigates to the sign in view, redirect to either the
    // `redirectPath` (if available) or the homepage
    next(redirectPath || '/');
  } else {
    // Redirect to the requested view
    next();
  }
});

export default router;
