import ViewWrapper from 'ewx-aphrodite/components/ViewWrapper.vue';

export default [
  {
    name: 'Roles',
    path: '/',
    component: () => import(/* webpackChunkName: "styleguide-elements-forms-form-control" */ './Request.vue'),
    meta: {
      isAuthRequired: true,
      isCategory: false,
    },
  },
];
