import { findRoute } from 'ewx-zeus/utils/router';

import Layout from './Layout.vue';
import config from './config';

import { Route } from './types';
// List of all `routes.js` files found in the `views` folder and subfolders
const files = require.context('@/views/', true, /routes\.ts/);

const routes = files.keys().reduce(
  (routeGroups, fileName) => {
    // All `routes` that are more than 2 levels deep are ignored as they are sub-routes
    if (fileName.match(/\//g)!.length > 2) {
      return routeGroups;
    }

    const moduleRoutes: Array<Route> = files(fileName).default;

    const isAuthRequired = moduleRoutes.some((route: Route) => route.meta?.isAuthRequired);
    const group = isAuthRequired ? 'authorized' : 'unAuthorized';

    const homepageRoute = config.homepage && findRoute(moduleRoutes, config.homepage);

    if (homepageRoute) {
      homepageRoute.alias = '/';
    }

    return {
      ...routeGroups,
      [group]: [...routeGroups[group], ...moduleRoutes],
    };
  },
  {
    authorized: [],
    unAuthorized: [],
  },
);

export default [
  {
    // Any not defined route will redirect to the homepage
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    // This is just a wrapper for authorized routes, so it doesn't have to match any path
    path: '/',
    component: Layout,
    children: routes.authorized,
    meta: {
      isAuthRequired: true,
    },
  },
  ...routes.unAuthorized,
];

export const { authorized } = routes;
