// @ts-nocheck
import { createApp } from 'vue';
import { dragscrollNext } from 'vue-dragscroll';

import { firebaseInstance } from 'ewx-zeus/utils/auth';
import directives from 'ewx-zeus/directives';
import mixins from 'ewx-zeus/mixins';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directivesVuetify from 'vuetify/directives';

import 'ewx-aphrodite';

import App from './App.vue';
import router from '@/router';
import store from '@/store';

let app;

// Does not render the app until the `firebase` instance is not available
firebaseInstance.onAuthStateChanged(() => {
  if (!app) {
    const vuetify = createVuetify({
      components,
      directivesVuetify,
    });

    app = createApp(App).use(vuetify);

    app.config.devtools = true;

    app.mixin(mixins);

    app.use(store).use(router).directive('dragscroll', dragscrollNext);

    Object.entries(directives).forEach(([name, directive]) => app.directive(name, directive));

    app.mount('#app');
  }
});
