
import { defineComponent } from 'vue';

import { firebaseInstance, signOut } from 'ewx-zeus/utils/auth';

import Button from 'ewx-aphrodite/components/Button.vue';
import Layout from 'ewx-aphrodite/components/Layout.vue';

export default defineComponent({
  components: {
    Button,
    Layout,
  },
  computed: {
    name: (): string => firebaseInstance.currentUser.displayName,
    picture: (): string => firebaseInstance.currentUser.photoURL,
  },
  methods: {
    signOut,
  },
});
