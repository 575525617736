import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-793881b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Layout = _resolveComponent("Layout", true)!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    account: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "account__picture",
          src: _ctx.picture
        }, null, 8, _hoisted_2)
      ])
    ]),
    menu: _withCtx(() => [
      _createVNode(_component_Button, {
        size: "wide",
        variant: "inverted",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Sign Out ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}