/**
 * The purpose of this file is to expose variables that will be used in different places accross the
 * application.
 *
 * This variables are not exposed on the `window` object to avoid pollution.
 */

export default {
  projectName: 'Admin App',
  defaultNavigationCategory: 'Ymir',
  // List of breakpoints, taken from `styles/config/breakpoints`
  breakpoints: {
    xs: 500,
    sm: 767,
    md: 992,
    lg: 1025,
    xl: 1200,
    xxl: 1400,
  },
};
